import $ from 'jquery';

export default function multiNav (target) {
    $.fn.menumaker = function() {
        var cssmenu = $(this);
        return this.each(function() {
            $(this).find("#menu-button").on('click', function(){
            $(this).toggleClass('menu-opened');
            var mainmenu = $(this).next('ul');
            if (mainmenu.hasClass('open')) {
                mainmenu.hide().removeClass('open');
            }
            else {
                mainmenu.show().addClass('open');
                if (settings.format === "dropdown") {
                mainmenu.find('ul').show();
                }
            }
            });

            cssmenu.find('li ul').parent().addClass('has-sub');

            cssmenu.find(".has-sub").prepend('<button type="button" class="navigation-primary__multinav-trigger"><span>Open</span></button>');
            cssmenu.find('.navigation-primary__multinav-trigger').on('click', function() {
                $(this).toggleClass('navigation-primary__multinav-trigger--active');
                if ($(this).siblings('ul').hasClass('open')) {
                    $(this).siblings('ul').removeClass('open').hide();
                }
                else {
                    $(this).siblings('ul').addClass('open').show();
                }
            });
        });
    };

    $(window).on('resize', function(e) {
        if ($( window ).width() > 900) {
            $(this).find('ul').show();
          }

          if ($(window).width() <= 900) {
            $(this).find('ul').hide().removeClass('open');
          }
    });

    $(target).menumaker({});
}